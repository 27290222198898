import colors from 'tailwindcss/colors';

// Hacky, but it's the only solution that works for now.
// https://github.com/tailwindlabs/tailwindcss/issues/4690
delete (colors as unknown as Record<string, unknown>)['lightBlue'];
delete (colors as unknown as Record<string, unknown>)['warmGray'];
delete (colors as unknown as Record<string, unknown>)['trueGray'];
delete (colors as unknown as Record<string, unknown>)['coolGray'];
delete (colors as unknown as Record<string, unknown>)['blueGray'];

/*
    === Color palette ===

    Based on Figma ML Design System
    https://www.figma.com/file/4SY7ZNkEVr8dXcVF2AMhrH/1.-MLAI---Core-Design-System-(2.4.1)?type=design&node-id=18%3A101&mode=design&t=5xucfhmT0T7Tl0oL-1

    Add other custom colors as needed.
*/
const customColors = {
    primary: {
        50: '#E9EDFF',
        100: '#D3DBFE',
        200: '#A7B7FE',
        300: '#7A94FD',
        400: '#4E70FC',
        500: '#224CFB',
        600: '#1D3FD3',
        700: '#1832AB',
        800: '#112279',
        900: '#0D185B'
    },
    neutral: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#E5E5E5',
        300: '#D4D4D4',
        400: '#A3A3A3',
        500: '#737373',
        600: '#525252',
        700: '#404040',
        800: '#262626',
        900: '#171717'
    },
    red: {
        50: '#FDF2F2',
        100: '#FDE8E8',
        200: '#FBD5D5',
        300: '#F8B4B4',
        400: '#F98080',
        500: '#F05252',
        600: '#E02424',
        700: '#C81E1E',
        800: '#9B1C1C',
        900: '#771D1D'
    },
    green: {
        50: '#F3FAF7',
        100: '#DEF7EC',
        200: '#BCF0DA',
        300: '#84E1BC',
        400: '#31C48D',
        500: '#0E9F6E',
        600: '#057A55',
        700: '#046C4E',
        800: '#03543F',
        900: '#014737'
    }
};

export default { ...colors, ...customColors };
