type ConfigType = { [index: string]: any };

const Config: ConfigType = {
    devMode: false, // for now, track everything - TODO: Make this true in production only once happy with analytics functionality
    enableTracking: true,
    GA_ID: 'G-0WF8WTBCXN',
    FULLSTORY_ID: 'o-1P826G-na1',
    MIXPANEL_ID: '07972a79aa3a8de43384eba066ad07f8',
    AMPLITUDE_ID: 'a93fee45e067c3610af1a65c1e472e50',
    GOSQUARED_ID: 'GSN-154529-N'
};

export default Config;
