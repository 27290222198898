import { Context, createContext, useContext } from 'react';

type AppContextType = {
    prevPage: {
        url: string;
        data: string;
        setData: (data: string) => void;
    };
};

export const AppContext: Context<AppContextType> = createContext({} as AppContextType);

export default function useAppContext() {
    return useContext(AppContext);
}
