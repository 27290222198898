import { ComponentType, useEffect, useState } from 'react';
import { router } from '@inertiajs/react';
import Analytics from 'analytics';
import googleAnalyticsPlugin from '@analytics/google-analytics';
import mixpanelPlugin from '@analytics/mixpanel';
import amplitudePlugin from '@analytics/amplitude';
import goSquaredPlugin from '@analytics/gosquared';
// import fullStoryPlugin from '@analytics/fullstory';
import { AnalyticsInstance } from 'analytics/lib/types';
import CryptoJS from 'crypto-js';
import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import medialakeAnalyticsPlugin from '@/Components/Analytics/MedialakeAnalyticsPlugin';
import UserInterface from '@/Interfaces/UserInterface';
import Config from '@/utils/config';

type CategoriesType = { [index: string]: string };

export const Categories: CategoriesType = {
    debug: 'Debug',
    sidebar: 'Sidebar',
    searchModal: 'Search Modal',
    dashboard: 'Dashboard',
    catalogue: 'Catalogue',
    connections: 'Connections',
    services: 'Services',
    syncModal: 'Sync Modal',
    settings: 'Settings',
    library: 'Library',
    assetDashboard: 'Asset Dashboard',
    albums: 'Albums',
    folders: 'Folders',
    album: 'Album',
    folder: 'Folder',
    duplicates: 'Duplicates',
    advancedSearch: 'Advanced Search',
    searchResults: 'Search Results',
    administration: 'Administration',
    profile: 'Profile',
    teams: 'Teams'
};

const withAnalytics = <T extends object>(WrappedComponent: ComponentType<T>) => {
    function NewComponent(props: any) {
        const [analytics, setAnalytics] = useState<AnalyticsInstance | null>(null);

        function initAnalytics(currentUser: UserInterface) {
            const initAnalytics = Analytics({
                app: 'medialake',
                version: '100',
                plugins: [
                    googleAnalyticsPlugin({
                        measurementIds: [Config.GA_ID]
                    }),
                    mixpanelPlugin({
                        token: Config.MIXPANEL_ID
                    }),
                    amplitudePlugin({
                        apiKey: Config.AMPLITUDE_ID,
                        options: {
                            trackingOptions: {
                                ip_address: false
                            }
                        }
                    }),
                    goSquaredPlugin({
                        projectToken: Config.GOSQUARED_ID,
                        trackLocal: false,
                        trackParams: true
                    }),
                    medialakeAnalyticsPlugin({
                        user: currentUser
                    })
                ]
            });

            const { id, name, username, email, current_team_id: currentTeamId } = currentUser;

            // userId should not be PII
            // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
            const hash = CryptoJS.SHA256(id);
            const userId = hash.toString(CryptoJS.enc.Hex);

            initAnalytics.identify(
                userId,
                {
                    name,
                    username,
                    email,
                    currentTeamId
                },
                {
                    plugins: {
                        all: true // identify user for all services
                    }
                }
            );

            setAnalytics(initAnalytics);
        }

        useEffect(() => {
            if (analytics?.page) {
                analytics.page();

                router.on('start', () => {
                    analytics.page();
                });
            }
        }, [analytics]);

        function track(category: string, action: string, label: string | null = null, value: number | null = null) {
            try {
                // For formatting the analytics event name
                const categoryName = Categories[camelCase(category)];
                const actionName = capitalize(action);
                // Make sure only pre-defined categories are used
                if (!categoryName) {
                    throw new Error('analytics category not recognized');
                }
                if (categoryName) {
                    analytics?.track(categoryName, {
                        action: actionName,
                        label: label,
                        value: value
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }

        return (
            <WrappedComponent
                hasAnalytics={Boolean(analytics)}
                initAnalytics={initAnalytics}
                track={track}
                {...props}
            />
        );
    }

    return NewComponent;
};

export default withAnalytics;
