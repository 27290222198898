/**
 * @depreciated Simply use route, it's available globally since it's attach to the window object. This hook uses the exact same func (route) but in unnecessary ways.
 * @returns
 */
export default function useRoute(): typeof route {
    const fn = window.route;
    if (!fn) {
        if (Object.prototype.hasOwnProperty.call(window, 'STORYBOOK_HOOKS_CONTEXT')) {
            return route;
        } else {
            throw new Error('Route function must be provided.');
        }
    }
    return fn;
}
