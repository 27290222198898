import { useEffect, useState } from 'react';
import { router } from '@inertiajs/react';

export default function useRememberPrevPage() {
    const [prevPage, setPrevPage] = useState({ url: '', data: '' });

    function setData(data: string) {
        setPrevPage((prev) => ({ ...prev, data }));
    }

    useEffect(() => {
        function saveCurrentPage() {
            const currPage = window?.location?.href ?? '';
            setPrevPage((prev) => ({ ...prev, url: currPage }));
        }

        const removeEventListener = router.on('before', saveCurrentPage);

        return () => {
            removeEventListener();
        };
    }, []);

    return { url: prevPage.url, data: prevPage.data, setData };
}
