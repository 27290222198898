import React, { useEffect } from 'react';
import DefaultErrorUI from '@/Components/DefaultErrorUI';
import withAnalytics from '@/Components/Analytics/WithAnalytics';
import { ErrorBoundary } from '@rollbar/react';
import { Provider as RollbarProvider } from '@rollbar/react';
import { AnalyticsContext } from '@/Hooks/useAnalytics';
import UserInterface from '@/Interfaces/UserInterface';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import useRememberPrevPage from '@/Hooks/useRememberPrevPage';
import { AppContext } from '@/Hooks/useAppContext';

const queryClient = new QueryClient();

type Props = {
    user: UserInterface;
    hasAnalytics: boolean;
    initAnalytics: (user: UserInterface) => void;
    pageview: () => void;
    track: (category: string, action: string, label: string | null, value?: number | null) => void;
    children: React.ReactNode;
};

function AppContainer(props: Props) {
    const { user, hasAnalytics, initAnalytics, pageview, track, children } = props;

    const prevPage = useRememberPrevPage();

    useEffect(() => {
        forceApplyDarkTheme();
        setupAnalytics(user);
        setupIntercomIfEnabled(user);
        initializeChonkyDefaults().then(() => console.info('Initialized Chonky defaults!'));
    }, []);

    function forceApplyDarkTheme(): void {
        localStorage.theme = 'dark';
    }

    function setupIntercomIfEnabled(user: UserInterface): void {
        const INTERCOM_ENABLED = import.meta.env.VITE_INTERCOM_ENABLED !== 'false';
        if (!INTERCOM_ENABLED) {
            console.warn('Intercom disabled!');
            return;
        }

        const { Intercom, INTERCOM_APP_ID: intercomId } = window as any;
        if (!user?.id || !intercomId || !Intercom) {
            console.warn('Could not set up Intercom: Missing user ID, Intercom App ID, or Intercom is not loaded.');
            return;
        }

        Intercom('boot', {
            app_id: intercomId,
            user_id: user.id,
            name: user.name,
            email: user.email,
            created_at: Math.floor(Date.now() / 1000),
            hide_default_launcher: true
        });

        console.info('Set up Intercom!');
    }

    function setupAnalytics(user: UserInterface): void {
        if (!user?.id) {
            console.warn('Could not set up analytics: Missing user ID.');
            return;
        }

        initAnalytics(user);
        console.info('Set up analytics!');
    }

    function getRollbarConfig(user: UserInterface): any {
        const config = {
            enabled: !(window as any).location.host.includes('localhost'),
            accessToken: '97a9eec0c2494a77a60a6c32f28598db',
            captureIp: 'anonymize',
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
                environment: (window as any).location.hostname,
                client: {
                    javascript: {
                        code_version: '1.0.0',
                        source_map_enabled: true
                    }
                }
            }
        };

        if (user?.id) {
            (config.payload as any).person = {
                id: user.id,
                username: user.name || '',
                email: user.email || ''
            };
        }

        return config;
    }

    async function initializeChonkyDefaults() {
        const setChonkyDefaults = (await import('chonky')).setChonkyDefaults;
        const ChonkyIconFA = (await import('chonky-icon-fontawesome')).ChonkyIconFA;
        setChonkyDefaults({ iconComponent: ChonkyIconFA as any });
    }

    const rollbarConfig = getRollbarConfig(user);

    return (
        <RollbarProvider config={rollbarConfig}>
            <QueryClientProvider client={queryClient}>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}

                {/* @ts-ignore prop type definition error: https://github.com/rollbar/rollbar-react/issues/87 */}
                <ErrorBoundary fallbackUI={DefaultErrorUI}>
                    <AnalyticsContext.Provider
                        value={{
                            hasAnalytics,
                            initAnalytics,
                            pageview,
                            track
                        }}
                    >
                        <AppContext.Provider
                            value={{
                                prevPage: prevPage
                            }}
                        >
                            {children}
                        </AppContext.Provider>
                    </AnalyticsContext.Provider>
                </ErrorBoundary>
            </QueryClientProvider>
        </RollbarProvider>
    );
}

export default withAnalytics(AppContainer);
