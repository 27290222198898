import useRoute from '@/Hooks/useRoute';

export default function DefaultErrorUI() {
    const route = useRoute();

    return (
        <div className="w-full h-full text-center mt-20 px-8">
            <h4 className="font-display text-2xl mb-3 text-black dark:text-neutral-300">Oops, something went wrong.</h4>
            <p className="max-w-xs mx-auto text-black dark:text-neutral-300">
                Please return to the{' '}
                <a href={route('dashboard')} className="underline underline-offset-2">
                    homepage
                </a>{' '}
                or contact us via chat in the bottom right corner.
            </p>
        </div>
    );
}
