import axios from 'axios';
import UserInterface from '@/Interfaces/UserInterface';

function MedialakeAnalyticsPlugin(config: { user: UserInterface }) {
    const { user } = config;
    const userId = user?.id;
    const teamId = user?.current_team_id ?? '';

    const origin = window.location.origin;
    const postEndpoint = `${origin}/ea/collect`;

    return {
        name: 'Medialake',
        page: () => {
            const location = window.location;
            const urlPath = `${location.pathname ?? '/'}${location.search ?? ''}`;

            const axiosPayload = {
                events: [
                    {
                        client_id: location?.host?.split('.')?.[0] ?? '',
                        user_id: userId,
                        team_id: teamId,
                        url_path: urlPath,
                        event_type: 'PageView',
                        event_category: 'PageView'
                    }
                ]
            };
            axios.post(postEndpoint, axiosPayload);
        },
        track: ({ payload }: any) => {
            const location = window.location;
            const urlPath = `${location.pathname ?? '/'}${location.search ?? ''}`;

            const axiosPayload = {
                events: [
                    {
                        client_id: location?.host?.split('.')?.[0] ?? '',
                        user_id: userId,
                        team_id: teamId,
                        url_path: urlPath,
                        event_name: payload?.properties?.label ?? '',
                        event_value: payload?.properties?.value ?? '',
                        event_type: payload?.properties?.action ?? '',
                        event_category: payload?.event ?? ''
                    }
                ]
            };
            axios.post(postEndpoint, axiosPayload);
        }
    };
}

export default MedialakeAnalyticsPlugin;
