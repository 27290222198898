import { Context, createContext, useContext } from 'react';
import UserInterface from '@/Interfaces/UserInterface';
// import useSideMenuNotifications from './useSideMenuNotifications';

type AnalyticsContextType = {
    hasAnalytics: boolean;
    initAnalytics: (currentUser: UserInterface) => void;
    pageview: () => void;
    track: (category: string, action: string, label: string | null, value?: number | null) => void;
};

export const AnalyticsContext: Context<AnalyticsContextType> = createContext({} as AnalyticsContextType);

export default function useAnalytics() {
    return useContext(AnalyticsContext);
}
